<template>
  <div class="zxpx">
    <div class="header">
      <div class="h1">在线培训</div>
      <div class="h2">灵活培训  提升技能</div>
    </div>
    <div class="content">
      <div class="h3">
        <div class="pro" v-for="(pro,index) in types" :key="index"   @click="goto(pro)">
          <div >
            <div class="img"><el-image :src="pro.image"></el-image></div>
            <div class="text text-overflow">{{pro.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg0">
      <div  class="t1 flex">
        <div class="t1left flex-1">
          <div style="font-size: 24px;font-weight: bolder;margin-bottom: 20px">{{datas.intro.title}}</div>
          <div v-html="datas.intro.text"></div>
          <div style="margin-top: 35px">
            <el-button type="primary"   @click="goto">查看全部课程</el-button>
          </div>
        </div>
        <div class="t1right">
          <div class="shipin">
            <video controls="controls" class="bg_video" >
              <source :src="datas.intro.video" type="video/mp4"/>
            </video>
          </div>
          <el-image fit="cover"  :src="datas.intro.image"></el-image>
        </div>
      </div>
    </div>

    <div class="t2">
      <div class="titles"> 看数字前沿</div>
      <div class="flex t2content">
        <div class="t2left">
          <div class="image" @click="gotoxq(t2arr[0])">
            <el-image  :src="t2arr[0].cover"></el-image>
          </div>
          <div class="desc">
            <div class="title text-overflow">{{t2arr[0].title}}</div>
            <div class="title2">{{t2arr[0].readCount}}次</div>
          </div>
        </div>
        <div class="flex-1">
          <div class="t2list"  @click="gotoxq(t2)" v-for="(t2,index) in t2arr" v-if="index!=0">
            <div class="img"> <el-image :src="t2.cover"></el-image></div>
            <div class="text text-overflow">{{t2.title}}</div>
          </div>
        </div>
      </div>
      <div class="bg3">
        <div class="titles"> 培训政策资讯</div>
        <div class="zhuti">
          <el-table
              :data="noticeList"
              @row-click="rowclick"
              style="width: 100%">
            <el-table-column
                type="index"
                label="序号"
                width="80"
                :index="indexMethod">
            </el-table-column>
            <el-table-column
                prop="title"
                label="标题">
            </el-table-column>
            <el-table-column
                prop="createTime"
                width="160"
                label="发布时间">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div  class="bg">
        <div class="zhuti">
          <div class="titles">
           在线培训数据统计
          </div>

          <div class="tubiao" ref="main">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onlinetrainingindex} from "../../request/moudle/zxpx";

export default {
  name: "index",
  data(){
    return {
      noticeList:[],
      t2arr:[],
      types:[],
      datas:'',
    }
  },
  mounted() {
    this.loadconfig()
  },
  methods:{
    indexMethod(index) {
      return index +1;
    },
    loadconfig(){
      this.$api.zxpx.onlinetrainingindex().then((res)=>{
        this.datas=res.data
        this.noticeList=res.data.noticeList
        this.t2arr=res.data.hotList
        this.types=res.data.courseMap
        this.init()
      })
    },
    goto(li){
      this.$router.push({
        path:'zxpxlist',
        query:{
          typeId:li.id ||''
        }
      })
    },
    init(){
      const color=['#257DB2','#12AC93','#9CB952','#F19B14','#BD372B','#65C5E3']
      const arr=[]
      for(let i=0;i<this.datas.categoryCount.x.length;i++){
        const obj={
          value:this.datas.categoryCount.x[i],
          itemStyle: {
            color: color[i]?color[i]:'#65C5E3',
          },
        }
        arr.push(obj)
      }
      const options={
        xAxis: {
          type: 'value',
          name: '(视频观看量)',
        },
        yAxis: {
          data:this.datas.categoryCount.y,
        },
        series: [
          {
            data:arr,
            itemStyle: {
              borderRadius:8
            },
            type: 'bar',
          },
        ]
      }
      this.$nextTick(()=>{
        const  myCharts = this.$echarts.init(this.$refs.main);
        myCharts.setOption(options);
      })
    },
    rowclick(row){
      this.$router.push({
        path:'/gonggaoxq',
        query:{
          id:row.id
        }
      })
    },
    gotoxq(item){
      this.$router.push({
        path:'/zxpxxq',
        query:{
          id:item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-table th.is-leaf{
  background-color: #fff!important;
}
.tubiao{
  width: 100%;
  height: 320px;
}
.zhuti{
  width: 1200px;
  margin: 0 auto;
}
.pro1{
  float: left;
  width: 203px;
  margin-left: 10px;
  cursor: pointer;
  .img1{
    width: 203px;
    height: 114px;
  }
  .text1{
    font-size: 12px;
    margin-top: 10px;
  }
}
.titles{
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
  margin-bottom: 35px;
  .titles2{
    color: #292929;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
  }
}
.content{
  background: #fff;
  padding: 20px 0;
}
.h3{
  width: 1200px;
  margin: 30px auto;
  overflow: hidden;
  .pro{
    margin:0 30px;
    float: left;
    cursor: pointer;
    .img{
      width: 140px;
      height: 140px;
    }
    .text{
      font-size: 16px;
      font-weight: 550;
      color: #000000;
      margin-top: 20px;
      text-align: center;
    }
  }
}
.zxpx {
  background: #F5F5F5;
  .header {
    height: 220px;
    background: url("../../assets/banner/zxpx.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 90px;
    text-align: center;
    color: #fff;

    .h1 {
      font-size: 42px;
      font-weight: 500;
    }

    .h2 {
      font-size: 22px;
      line-height: 80px;
    }



  }
  .bg0{
    background:linear-gradient(0deg,#FFFFFF,#F0F5FE);
  }
  .bg1{
    background: #fff;
  }
  .bg3{
    background:url("../../assets/zxpx/img.png") no-repeat;
    background-size: 100% 100%;
    padding: 56px 0;
  }
  .t1{
    width: 1200px;
    margin: 0 auto;
    padding: 56px 0;
    .t1left{
      padding: 60px;
      line-height: 35px;
    }
    .button{
      width: 150px;
      margin: 50px auto;
      padding: 12px 45px;
      border-radius: 6px;
      background: #E9C79A;
      font-size: 16px;
      font-weight: 550;
      color: #fff;
      cursor: pointer;
    }
    .t1right{
      width: 641px;
      height: 432px;
      margin-top: 55px;
      position: relative;
      .shipin{
        width: 298px;
        height: 200px;
        position: absolute;
        top: 59px;
        left: 44px;
        z-index: 9;
        video{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .t2{
    background: #fff;
    .t2content{
      width: 1200px;
      margin: 0 auto;
      padding: 50px 0;
    }
    .t2left{
      width: 450px ;
      height: 320px;
      position: relative;
      cursor: pointer;
      .image{
        height: 100%;
      }
    }
    .t2list{
      float: left;
      width: 230px;
      cursor: pointer;
      .img{
        height: 130px;
        margin-left: 15px;
        background: #0078F2;
      }
      .text{
        margin: 10px 15px;
      }
    }
  }
  .t3{
    width: 1200px;
    margin: 0px auto;
    overflow: hidden;
    padding-bottom: 152px;
    .mingren{
      width: 243px;
      height: 640px;
      float: left;
      background: url("../../assets/home/shi.png");
      background-size: 100% 100%;
      margin-right: 50px;
    }
  }
  .news{
    width: 1200px;
    margin: 0px auto;
    padding-bottom: 100px;
    overflow: hidden;
    .newlist{
      width: 280px;
      height: 235px;
      float: left;
      margin-right: 20px;
      position: relative;
      margin-bottom: 20px;
      cursor: pointer;
      .img{
        height: 180px;
        background: #F1F3F3;
      }
    }
  }
  .desc{
    background: rgba(0,0,0,0.7);
    opacity: 0.6;
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    .title{
      font-weight: 550;
    }
    .title2{
      text-align: right;
      font-size: 12px;
    }
  }
}
</style>